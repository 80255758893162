<template>
  <div id="preview">
    <div class="inner">
      <div class="image fit">
        <img src="images/hurricane.jpg" alt="Hurricane by Shutterwax" />
      </div>
      <div class="content">
        <header>
          <h2>Hurricane</h2>
        </header>
        <iframe style="border: 0; width: 100%; height: 42px;" src="https://bandcamp.com/EmbeddedPlayer/album=24915248/size=small/bgcol=ffffff/linkcol=0687f5/transparent=true/" seamless><a href="http://shutterwax.com/album/hurricane">Hurricane by Shutterwax</a></iframe>
        <p>A song to help you understand weather</p>
      </div>
    </div>
    <router-link :to="{ name: 'Frank', params: {} }" class="nav previous">
      <span class="fa fa-chevron-left"></span>
    </router-link>
    <router-link :to="{ name: 'Home', params: {} }" class="nav next">
      <span class="fa fa-chevron-right"></span>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'HurricanePage',
  components: {

  },
  mounted() {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
